<template>
  <div class="main-content" v-if="canAdministrate">
    <NotificationEditionModal/>
    <NotificationDeletionModal/>
    
    <b-card  v-if="$store.state.fleet.phoneAudit"  no-body>
            <div v-if="$store.state.fleet.phoneAudit.fallErrors" class="text-light col-12 bg-danger" style="padding: 15px">
                <p>{{$store.state.fleet.phoneAudit.fallErrors + ' ' + $t('rooms are not always covered by a phone for fall detection')}} <b-button v-b-toggle="'collapse-fall'" class="m-1">{{ $t('View Details...') }}</b-button></p>                 
                

                <!-- Element to collapse -->
                <b-collapse id="collapse-fall" >
                  <b-row>
                    <template v-for="error in $store.state.fleet.phoneAudit.errors.filter(p => p.type === 'fall')">
                      <b-col cols="3" style="margin-top:5px" :key="error.key">
                        {{error.rslabel + ' / ' + error.label }}
                      </b-col>
                      <b-col cols="9" style="margin-top:5px" :key="error.key">
                          <template v-for="(segment,index) in error.segments">
                            <div :key="'errfallseg_' + index">{{$t('does not receive notification from {start} to {end}', segment) }}</div>
                          </template>

                      </b-col>
                    </template>
                  </b-row>
                </b-collapse>
            </div>
            <div v-if="$store.state.fleet.phoneAudit.bedexitErrors" class="text-light col-12 bg-warning" style="padding: 15px">              
              <p>{{$store.state.fleet.phoneAudit.bedexitErrors + ' ' + $t('rooms are not always covered by a phone for bed exit detection')}} <b-button v-b-toggle="'collapse-bedexit'" class="m-1">{{ $t('View Details...') }}</b-button></p>                 
                <b-collapse id="collapse-bedexit">
                  <b-row>
                    <template v-for="error in $store.state.fleet.phoneAudit.errors.filter(p => p.type === 'bedexit')">
                      <b-col cols="3" style="margin-top:5px" :key="error.key">
                        {{error.rslabel + ' / ' + error.label }}
                      </b-col>
                      <b-col cols="9" style="margin-top:5px" :key="error.key">
                          <template v-for="(segment,index) in error.segments">
                            <div :key="'errbedseg_' + index">{{$t('does not receive notification from {start} to {end}', segment) }}</div>
                          </template>

                      </b-col>
                    </template>
                  </b-row>
                </b-collapse>
            </div>

    </b-card>

    <h1 id="phonenumbers_tittle">{{ $t('Phone Numbers Management') }}</h1>
      <b-row>
      <b-col cols="6">
        <b-btn @click="createUser" variant="primary" id="phonenumbers_add">
          <font-awesome-icon icon="plus"  />
          {{ $t('Add a Phone Number') }}
          </b-btn>        
      </b-col>
      <b-col cols="6">
        <!--
        <VueLoadingButton  class="float-right" :loading="isLoading" :styled="true" @click.native="loadUsers">Refresh</VueLoadingButton>
        -->
      </b-col>
    </b-row>

    <b-table :busy="isLoading"          
             borderless                            
             hover
             responsive
             light 
             class="w-auto" 
             style="margin-bottom: 4rem;"
            :items="$store.state.fleet.users ? $store.state.fleet.users.filter(u => u.isNotificationUser === true && u.organizationLabel === $store.state.fleet.currentOrganization) : []" 
            :fields="fields"
            id="phonenumbers_table">
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>{{ $t('Loading...') }}</strong>
        </div>
        </template>
      <template v-slot:cell(phone)="row">
          <div class="d-flex flex-row">
              <div class="p-2">{{ row.item.firstName  }} ({{ row.item.phone }})</div>
              
              <b-dropdown size="lg" dropleft  variant="link" toggle-class="text-decoration-none" no-caret class="m-2 top" >
                  <template #button-content>
                      <font-awesome-icon icon="ellipsis-v" />
                  </template>
                  
                  <b-dropdown-item class="p-2 top" v-if="$store.state.user.knownUsers && $store.state.user.knownUsers.find(p => p.oid === row.item.oid) != null"  @click="revokePattern(row.item)"> <font-awesome-icon icon="trash-alt" />{{ $t('Revoke Unlock pattern') }}</b-dropdown-item>
                  <b-dropdown-item class="p-2 top" @click="editUser(row.item)"> <font-awesome-icon icon="wrench" />{{$t('Edit...') }}</b-dropdown-item>
                  <b-dropdown-item class="p-2 top" @click="deleteUser(row.item)"> <font-awesome-icon icon="user-slash" />{{ $t('Delete Phone Number') }}</b-dropdown-item>                  
              </b-dropdown>              
          </div>      
      </template>

      <template v-slot:cell(edit)="row">
        <b-button size="sm"  variant="primary" class="btnedit left"  @click="editUser(row.item)" >
          <font-awesome-icon icon="edit" />
          {{ $t('Edit') }}
        </b-button>
      </template>
      <template v-slot:cell(revoke)="row">
        <b-button size="sm"  variant="light" right @click="deleteUser(row.item)" class="btndelete right">
          <font-awesome-icon icon="trash-alt"  />
          {{ $t('Delete') }}
        </b-button>
      </template>
    </b-table>
  </div>
  <div class="main-content" v-else>
            <b-row>
      <b-col>
        <div class="not-found-wrap text-center">
          <h1 class="text-25">
            {{ $t('Access Denied') }}
          </h1>
          <p class="text-36 subheading mb-3">{{ $t("You don't have enough privileges to administrate Phone Numbers.")}}</p>
          <p class="mb-5 text-muted text-18">
            {{ $t('You need administration role, or medical administrator role to access this page. If you think that you should access to this page, please contact your administrator or mintt support at ') }} <a href="mailto:support@mintt.care">support@mintt.care</a>
          </p>
          <a class="btn btn-lg btn-primary btn-rounded" href="/" id="gotohome">{{ $t('Go back to home') }}</a
          >
        </div>
      </b-col>
    </b-row>  
  </div>
</template>


<script>
import { SPService } from "@/services/sp.service";
import { AuthService } from "@/services/auth.service";
import NotificationEditionModal from "@/components/ISA/admin/NotificationEditionModal"
import NotificationDeletionModal from "@/components/ISA/admin/NotificationDeletionModal"

export default {
  name: "PhoneManagement",  
  data() {
    return {
      users: null,
      isLoading: false,
      auditResult: null,
    };
  },
  computed: {
    fields() {
      let fields = [];
     /* if(!this.$store.state.user.isMobile) fields.push(
        {
          label: 'Phone Name',
          key: 'username',
          sortable: true
        });*/
        fields.push(
        {
          label: this.$t('Phone Number'),
          key: 'phone',
          sortable: true
        });
        /*if(!this.$store.state.user.isMobile)
        {
          fields.push({ key: 'edit', label: 'Edit' });
          fields.push({ key: 'revoke', label: 'Revoke' });
        }
          
      if(!this.$store.state.user.isMobile)
        for(let rs of this.$store.state.fleet.roomsetList) {
          if(rs.organizationLabel !== this.$store.state.fleet.currentOrganization) continue;
          fields.push({ key: 'rightsOn' + rs.oid, label: 'Notify on ' + rs.label, sortable: true})
        }    
        */    
      
      return fields;
    },
    canAdministrate()  {
      return AuthService.canAdministrate(this.$store.state.user.currentUser.role);
    }
  },
  components: {
    NotificationEditionModal,
    NotificationDeletionModal,
  },
    watch: {
    '$store.state.fleet.currentOrganization': async function(val,oldVal) {      
        // this.$store.dispatch('fleet/loadUsers');
    },
    "$store.state.user.notificationEditorShow": function() {
      this.$store.dispatch('fleet/loadUsers');
    },  
  },
  methods: {
    gomycell(key) {
      return `cell(rightsOn${key})`; // simple string interpolation
    },
    rightmycell(key) {
      return `data.item.rightsOn${key}`; // simple string interpolation
    },
    idmycell(u,key) {
      return `${u}_${key}`; // simple string interpolation
    },
    hasRightsOn(user, oid) {
      return true;
    },

    createUser() {      
      let user = { create: true}
      user.role = 3;
      user.isNotificationUser = true;
       this.$store.commit('user/SHOW_NOTIFICATIONEDITOR',user);     

      const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'phonecreate',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
    },
    editUser(user) {            
       this.$store.commit('user/SHOW_NOTIFICATIONEDITOR',user);     
       const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'phoneedit',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data); 
    },
    deleteUser(user) {
      
       this.$store.commit('user/SHOW_NOTIFICATIONDELETOR',user);      
       const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'phonedelete',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
    },

    
  },
  mounted() {
    this.$store.dispatch('fleet/loadUsers');
  }, 
};
</script>

<style lang="scss" >

</style>
