<template>
  <b-modal hide-footer no-close-on-backdrop v-model="showNotificationEditor" @close="close" @hidden="close2" :title="$t('Phone Notification')">
    <div>
      <b-card >
        <validation-observer ref="observer" v-slot="{ invalid }" >          
          <b-form @submit.prevent="apply" id="phonenumbers_addphoneform">
              <validation-provider
              :name="$t('Phone Number')"
              :rules="{ required: true, is_not: 0 }"
              v-slot="validationContext"
            >
            <b-form-group
              v-if="notification > 0"
              label-cols-sm="3"
              :label="$t('Phone Number') +':'"
              label-align-sm="left"
              label-for="phone"
            >
              <vue-tel-input
                @validate="validatePhone"
                @blur="onBlur"
                size="lg"
                v-model="phone"
                mode="international"
                defaultCountry="be"
                :onlyCountries="phoneCountries"
                :state="pnValid"               
                :dropdownOptions = dropdownOptions
                aria-describedby="input-3-live-feedback"
                id="addphone_number"
              >
              <template v-slot:arrow-icon> <span></span> </template>
            </vue-tel-input>
              <span class="invalid-feedback"
                  id="input-3-live-feedback" :style="pnValidStyle"
                >{{ $t('The phone number is invalid') }}</span>
            </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('Label')" :rules="{ required: true  }" v-slot="validationContext">
                    <b-form-group label-cols-sm="3"
                                  :label="$t('Label')"
                                  label-align-sm="left"
                                  label-for="Label">
                        <b-form-input v-model="firstName"
                                      :state="getValidationState(validationContext)"
                                      aria-describedby="input-3-live-feedback"
                                      id="addphone_label"></b-form-input>
                        <b-form-invalid-feedback id="input-3-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
            </validation-provider>


            <validation-provider              
              :name="$t('Notification Type')"
              :rules="{ required: true, is_not: 0 }"
              v-slot="validationContext"
            >
            <b-form-group
              label-cols-sm="3"
              :label="$t('Type:')"
              label-align-sm="left"
              class="mb-0"
              label-for="notification"
              id="addphone_type"
            >
              <b-form-radio-group class="pt-2" 
              :options="notifications" 
              v-model="notification"
              :state="getValidationState(validationContext)"
              aria-describedby="input-2-live-feedback"
              ></b-form-radio-group>
              <span class="invalid-feedback"
                  id="input-2-live-feedback" style="display: block;"
                >{{ validationContext.errors[0] }}</span>
            </b-form-group>
            </validation-provider>
            

            <b-form-group
              label-cols-sm="3"
              :label="$t('Language:')"
              label-align-sm="left"
              label-for="preferredLanguage"
              v-if="notification > 0"
              id="addphone_language"
            >
              <b-form-select v-model="preferredLanguage" :options="preferredLanguages"></b-form-select>
            </b-form-group>

            <h5 style="margin-top: 20px; margin-down: 10px">{{ $t('Receives notification about:') }}</h5>
            <b-row>
              <b-col cols="6">
                <b-row>
                  <b-col cols="6">{{ $t('Falls:') }}</b-col>
                  <b-col cols="6">                    
                    <label class="switch switch-primary mr-3" >
                      <input type="checkbox" v-model="enableFallNotification" id="addphone_enableFall"/>
                      <span class="slider"></span>
                    </label>    
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col cols="6">{{ $t('Bed Exit:') }}</b-col>
                  <b-col cols="6">                    
                    <label class="switch switch-primary mr-3" >
                      <input type="checkbox" v-model="enableBedExitNotification" id="addphone_enableBedExit"/>
                      <span class="slider"></span>
                    </label>    
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <h5 v-if="notification > 0" style="margin-top: 20px; margin-down: 10px">{{ $t('Receives notification from Care Units:') }}</h5>                            
                <b-form-group 
                      v-for="acc in accesses"
                      :key="acc.oid"
                      label-cols-sm="3"
                      :label="acc.label"
                      label-align-sm="left"
                      >
                    
                    <label class="switch switch-primary mr-3" >
                      <input type="checkbox" v-model="acc.value" @change="updateRoomAccesses"/>
                      <span class="slider"></span>
                    </label>                    
                </b-form-group>

             <h5 style="margin-top: 20px; margin-down: 10px">{{ $t('Receives notification from:') }}</h5>
            <b-row v-if="enableFallNotification || enableBedExitNotification" >               
              <b-form-radio-group class="pt-2" 
              :options="notificationFromOptions" 
              v-model="isNotificationSourceCustomRoomList" 
              id="addphone_notificationsfrom"            
              ></b-form-radio-group>
            </b-row>
            <b-row
              v-if="isNotificationSourceCustomRoomList && (enableFallNotification || enableBedExitNotification)"
            >              
              <b-col cols="4">
                <h6>{{ $t('Custom room list:') }}</h6>
              </b-col>
              <b-col cols="4">
                <b-button variant="secondary" @click="roomsCheckAll(true)">{{ $t('Check all') }}</b-button>
              </b-col>    
              <b-col cols="4">
                <b-button variant="secondary" @click="roomsCheckAll(false)">{{ $t('Uncheck all') }}</b-button>
              </b-col>        
              <hr/>
                <b-col cols="6" v-for="acc in roomAccesses" :key="acc.oid">
                <b-form-group                                             
                      label-cols-sm="6"
                      :label="acc.label"
                      label-align-sm="left"                      
                      >
                    
                    <label class="switch switch-primary mr-3" >
                      <input type="checkbox" v-model="acc.value" />
                      <span class="slider"></span>
                    </label>                    
                </b-form-group>   
              </b-col>
              
                    
            </b-row>

            <h5 v-if="enableFallNotification || enableBedExitNotification" style="margin-top: 20px; margin-down: 10px">{{ $t('When to receive notifications:') }}</h5>
            <b-row v-if="enableFallNotification || enableBedExitNotification">               
              <b-form-radio-group  class="pt-2" 
              :options="notificationScheduleOptions" 
              v-model="isFallNotificationScheduled"  
              id="addphone_notificationswhen"            
              ></b-form-radio-group>

            </b-row>
            <b-row
              v-if="isFallNotificationScheduled && (enableFallNotification || enableBedExitNotification)"
            >
              <b-col cols="6">{{ $t('from:') }}</b-col>
              <b-col cols="6">
                <b-form-timepicker id="fallStart" locale="de" hourCycle="h23" :hour12="false"  minutes-step="15" v-model="fallStart" :state="true"></b-form-timepicker>                
              </b-col>
            </b-row>
            <b-row
              v-if="isFallNotificationScheduled && (enableFallNotification || enableBedExitNotification)"
            >
              <b-col cols="6">{{ $t('to:') }}</b-col>
              <b-col md="6" class="mb-30">                
                <b-form-timepicker @context="onContext" id="fallEnd" locale="de" hourCycle="h23"  minutes-step="15" :hour12="false" v-model="fallEnd" :state="true"></b-form-timepicker>
            </b-col>
            </b-row>
            <hr/>       
            <b-button  class="float-left" @click="close" id="addphone_cancel">
                {{ $t('Cancel')}}
            </b-button>
            <b-button :disabled="invalid" :loading="requesting" variant="primary" class="float-right" type="submit" id="addphone_apply">
                {{ $t('Apply')}}
            </b-button>
            
          </b-form>
        </validation-observer>
      </b-card>
    </div>
    
  </b-modal>
</template>

<script>
import { AuthService } from "@/services/auth.service";
import { SPService } from "@/services/sp.service";
import ROLES from "@/constants/roles";
import Multiselect from "vue-multiselect";
import { API_URL } from '@/.env'
var generator = require('generate-password');


export default {
  components: {    
    Multiselect
  },
  data() {
    return {
      requesting: false,
      showNotificationEditor: false,
      create: false,
      loading: false,
      username: null,
      firstName: null,
      lastName: null,
      password: null,
      email: null,
      role: null,
      accesses: null,
      roomAccesses: null,
      roles: [
        { value: ROLES.kiosk, text: this.$t("kiosk") },
        { value: ROLES.doctor, text: this.$t("caregiver") },
        { value: ROLES.admin, text: this.$t("admin") }
      ],
      phone: null,
      pnValid: false,
      pnE164: null,
      isCall: null,
      notification: null,
      notifications: [
        // { value: "0", text: this.$t("None") },
        { value: "1", text: this.$t("Phone Call") },
        { value: "2", text: this.$t("SMS") }
      ],
      enableBedExitNotification: null,
      enableFallNotification: null,
      preferredLanguage: null,     
      phoneCountries: ["AU", "BE", "CH", "DE", "DK", "FR", "GB", "IE", "LU", "NL", "SE", "US"],
      isFallNotificationScheduled: false,
      isBedExitNotificationScheduled: false,
      
      fallStart: "00:00:00",
      fallEnd: "00:00:00",
      bedExitStart: null,
      bedExitEnd: null,
      
      isNotificationSourceCustomRoomList: false,
      notificationSourceCustomRoomList: [],
      dropdownOptions:
      {
        showFlags: true,
        showDialCodeInSelection: false,
        showDialCodeInList: false,
        showSearchBox: false,
        disabled: true
      }
    }
  },
  computed: {
    pnValidStyle() {
      console.log("display:" + (this.pnValid ? 'none;' : 'block;'))
      return "display:" + (this.pnValid ? 'none;' : 'block;')
    },
    options() {
      return this.$store.state.fleet.roomsetList.filter(r => r.organizationLabel === this.$store.state.fleet.currentOrganization && r.label.charAt(0) !== '_').map(p => {
        return { name: p.label };
      });
    },
    preferredLanguages()
    { 
      return [
        { value: "en", text: this.$t("English") },
        { value: "fr", text: this.$t("French") },
        { value: "nl", text: this.$t("Dutch") },
        { value: "zh", text: this.$t("Chinese") },                
        { value: "de", text: this.$t("German") },
        { value: "it", text: this.$t("Italian") },
        { value: "ja", text: this.$t("Japanese") },
        { value: "pt", text: this.$t("Portugese") },
        { value: "ru", text: this.$t("Russian") },
        { value: "sp", text: this.$t("Spanish") }
      ]
    },
    notificationScheduleOptions() 
    {      
      return [
        { value: false, text: this.$t('24/7 - Always')},
        { value: true, text: this.$t('on time schedule')},
      ]
    },     
    notificationFromOptions() {
      return [
      { value: false, text: this.$t('All Rooms in Care Unit')},
      { value: true, text: this.$t('Custom Room List')},
    ]
    }, 
    
  },
  watch: {
    "$store.state.user.notificationEditorShow": function(val) {
      this.showNotificationEditor = val;
      this.requesting = false
      this.loadUser();
    },
    "$store.state.user.notificationEditorData": function(user) {
      this.requesting = false
      this.loadUser();
    },
  },
  methods: {
    roomsCheckAll(v) {
      for(let r of this.roomAccesses)
        r.value = v;
    },
    close(e) {
      console.log(e);
      console.log('closing call!')
      e.preventDefault()
      this.$store.commit("user/SHOW_NOTIFICATIONEDITOR", null);
    },
    close2(e) {
      console.log(e);
      console.log('closing2 call!')
      e.preventDefault()
     // this.$store.commit("user/SHOW_NOTIFICATIONEDITOR", null);
    },
    onContext(ctx) {
      //debugger
        ctx.hourCycle = "h23";
        // this.context = ctx
    },
    loadUser() {
      this.notification = 0
      this.isCall = false
      this.oid = null
      this.password = null
      
      this.role = 3
      this.phone = null
      this.firstName = null
      this.lastName = null
      this.email = null
      this.accesses = []
      this.pnValid = false
      this.pnE164 = null
      this.isNotificationSourceCustomRoomList = false;
      this.notificationSourceCustomRoomList = [];

      this.accesses = [];        

      if (this.$store.state.user.notificationEditorData) {
        this.create = this.$store.state.user.notificationEditorData.create;
        this.isCall = this.$store.state.user.notificationEditorData.isCall;
        if(this.create)
            this.notification = 1
        else this.notification = this.isCall ? 1 : 2;

        this.oid = this.$store.state.user.notificationEditorData.oid;
        this.username = this.$store.state.user.notificationEditorData.username;
        if(!this.username)  this.username = 'NOTIF_' + generator.generate({ length: 16, numbers: true });
        this.password = this.password = generator.generate({
          length: 16,
          numbers: true
        });
        this.role = this.$store.state.user.notificationEditorData.role;
        this.phone = this.$store.state.user.notificationEditorData.phone;
        
        this.firstName = this.$store.state.user.notificationEditorData.firstName;
        this.lastName = this.$store.state.user.notificationEditorData.lastName;
        this.email = this.$store.state.user.notificationEditorData.email;

        if(!this.$store.state.user.notificationEditorData.config) {
          
          this.enableFallNotification = true;
          this.enableBedExitNotification = true;
          this.preferredLanguage = this.$i18n.locale;
          this.isFallNotificationScheduled = false;
          this.isNotificationSourceCustomRoomList = false;

        } else {
        this.enableFallNotification  = this.$store.state.user.notificationEditorData.config.enableFallNotification;
        this.enableBedExitNotification  = this.$store.state.user.notificationEditorData.config.enableBedExitNotification;

        this.preferredLanguage = this.$store.state.user.notificationEditorData.config.preferredLanguage;        

        this.isFallNotificationScheduled = this.$store.state.user.notificationEditorData.config.isFallNotificationScheduled;
        if(this.isFallNotificationScheduled && this.$store.state.user.notificationEditorData.config.fallNotificationScheduledRanges.length)
        {
          this.fallStart = ('0' + this.$store.state.user.notificationEditorData.config.fallNotificationScheduledRanges[0].startHourLocalTime).slice(-2)
                          + ':'
                          + ('0' + this.$store.state.user.notificationEditorData.config.fallNotificationScheduledRanges[0].startMinuteLocalTime).slice(-2)

          this.fallEnd = ('0' + this.$store.state.user.notificationEditorData.config.fallNotificationScheduledRanges[0].endHourLocalTime).slice(-2)
                          + ':'
                          + ('0' + this.$store.state.user.notificationEditorData.config.fallNotificationScheduledRanges[0].endMinuteLocalTime).slice(-2)
          
        }
        }

        for (let rs of this.$store.state.fleet.roomsetList.filter(f => f.organizationLabel === this.$store.state.fleet.currentOrganization && f.label.charAt(0) !== '_')) {          
          this.accesses.push({ label: rs.label, oid: rs.oid, value: this.create || rs.authorizedUserOIDList.find(u => u === this.oid) != null});          
        } 
        this.updateRoomAccesses(true);
        this.isNotificationSourceCustomRoomList = this.$store.state.user.notificationEditorData.config ? this.$store.state.user.notificationEditorData.config.isNotificationSourceCustomRoomList : false;
        if(this.isNotificationSourceCustomRoomList)
        {
        console.log(this.$store.state.user.notificationEditorData.config.notificationSourceCustomRoomList)
        console.log(this.roomAccesses);
        for(const v of this.$store.state.user.notificationEditorData.config.notificationSourceCustomRoomList) {
          
          const r = this.roomAccesses.find(x => x.oid === v);
          if(r) 
          {            
            r.value = true;          
          }
        }
        }
              
      } 
      
    },
    validatePhone(o) {
      this.pnValid = o.valid || false;      
      if(o.valid && o.number){
        this.pnE164 = o.number;
      }
    },
    onBlur() {
      let phone = this.phone.replace(/\s/g,'');
      if(this.pnValid) {
        this.pnE164 = phone;
      }
    },
    async apply() {
      // here we know that the form is validated
      this.requesting = true      
      try
      {        
        let nscrl = this.roomAccesses ? this.roomAccesses.filter(p => p.value).map(p => { return p.oid }) : [];           
        console.log(nscrl);     
        if(this.create) { // here we create a new user
        // get first roomsetOID
        let frs = this.accesses[0];      
        
        var payload =    {              
              "username": 'NOTIF_' + generator.generate({ length: 16, numbers: true }),
              "password": this.password,
              "email": this.email,
              "phone": this.pnE164,
              "isCall": this.notification == 1,
              "firstName": this.firstName || "",
              "lastName": this.lastName || "",
              "role": 3, // caregiver
              "isNotificationUser": true,
              "needNewPassword": true,
              "organizationLabel": this.$store.state.user.currentUser.organizationLabel,
              "roomsetOID": frs.oid,
              "config": 
              {
                  "preferredLanguage": this.preferredLanguage ? this.preferredLanguage : 'en',
                  "enableFallNotification": this.enableFallNotification,
                  "enableBedExitNotification": this.enableBedExitNotification,
                  "isNotificationSourceCustomRoomList": this.isNotificationSourceCustomRoomList,
                  "notificationSourceCustomRoomList": nscrl,

                  isFallNotificationScheduled: this.isFallNotificationScheduled,
                  fallNotificationScheduledRanges: [{
                    startHourLocalTime: parseInt(this.fallStart.substring(0,2),10) , // start hour UTC XX:XX:XX
                    startMinuteLocalTime: parseInt(this.fallStart.substring(3,5),10), // start minute UTC
                    endHourLocalTime: parseInt(this.fallEnd.substring(0,2),10), // end hour UTC
                    endMinuteLocalTime: parseInt(this.fallEnd.substring(3,5),10), // end minute UTC                    
                  }],

                  isBedNotificationScheduled: this.isFallNotificationScheduled,
                  bedNotificationScheduledRanges: [{
                    startHourLocalTime: parseInt(this.fallStart.substring(0,2),10) , // start hour UTC
                    startMinuteLocalTime: parseInt(this.fallStart.substring(3,5),10), // start minute UTC
                    endHourLocalTime: parseInt(this.fallEnd.substring(0,2),10), // end hour UTC
                    endMinuteLocalTime: parseInt(this.fallEnd.substring(3,5),10), // end minute UTC
                  }],
              }
          }                   
          console.log(payload);
          
        let answer = await SPService.createUser(API_URL.url, this.$store.state.user.currentUser.jwt, payload );
        if(answer.status !==  200) {          
              this.$bvToast.toast(`Error creating user ${this.pnE164}.\n${answer.status}: ${answer.message}`, {
              title: this.$t('Phone Management'),            
              solid: true,
              variant: 'warning',
              duration: 5000
              });
        }
        else          
          this.$bvToast.toast(`Phone ${this.pnE164} added with success`, {
              title: this.$t('Phone Management'),            
              solid: true,
              variant: 'success',
              duration: 5000
              });
        console.log(answer)
        this.oid = answer.data.oid;
        console.log('got oid ' + this.oid);
        try
        {
        let answer = await SPService.updateUser(API_URL.url, this.$store.state.user.currentUser.jwt, {
                "oid": this.oid,      
                "isNotificationUser": true,  
                  "config": 
                  {
                      "preferredLanguage": this.preferredLanguage
                  }
                });      
        } catch(err) {

        }
      

        
      }
      else {
        
        const payload = {              
              "oid": this.oid,
              "username": this.username,
              "password": this.password,
              "email": this.email,
              "phone": this.pnE164,
              "isCall": this.notification == 1,
              "firstName": this.firstName,
              "lastName": this.lastName,
              "role": this.role,
              "isNotificationUser": true,
              "needNewPassword": true,
              "config": 
              {
                  "preferredLanguage": this.preferredLanguage,
                  "enableFallNotification": this.enableFallNotification,
                  "enableBedExitNotification": this.enableBedExitNotification,
                  "isNotificationSourceCustomRoomList": this.isNotificationSourceCustomRoomList,
                  "notificationSourceCustomRoomList": this.isNotificationSourceCustomRoomList ? nscrl : [],

                  isFallNotificationScheduled: this.isFallNotificationScheduled,
                  fallNotificationScheduledRanges: [{
                    startHourLocalTime: parseInt(this.fallStart.substring(0,2),10) , // start hour UTC
                    startMinuteLocalTime: parseInt(this.fallStart.substring(3,5),10), // start minute UTC
                    endHourLocalTime: parseInt(this.fallEnd.substring(0,2),10), // end hour UTC
                    endMinuteLocalTime: parseInt(this.fallEnd.substring(3,5),10), // end minute UTC
                  }],

                  isBedNotificationScheduled: this.isFallNotificationScheduled,
                  bedNotificationScheduledRanges: [{
                    startHourLocalTime: parseInt(this.fallStart.substring(0,2),10) , // start hour UTC
                    startMinuteLocalTime: parseInt(this.fallStart.substring(3,5),10), // start minute UTC
                    endHourLocalTime: parseInt(this.fallEnd.substring(0,2),10), // end hour UTC
                    endMinuteLocalTime: parseInt(this.fallEnd.substring(3,5),10), // end minute UTC
                  }],
              }
          }
        console.log(payload)
        console.log('notification: ' + this.notification);
        let answer = await SPService.updateUser(API_URL.url, this.$store.state.user.currentUser.jwt, payload);
      }
      // handle user rights

      // ensure user has all its accesses
      // ensure user has all its accesses
      console.log('checking accesses')
      
      for(const acc of this.accesses)
      {
        let rs = this.$store.state.fleet.roomsetList.find(p => p.oid === acc.oid);
        if(rs) {
            if(acc.value && rs.authorizedUserOIDList.find(u => u === this.oid) == null) {
              // needs to add user rights              
              let answ = await SPService.addUserToRoomset(API_URL.url, this.$store.state.user.currentUser.jwt, this.oid, rs);              
              if(answ.status ===  200) {          
                this.$store.dispatch('fleet/updateRoomsetAuthorizedList', answ.data);
                console.log("updating roomsets")
              }
              this.$bvToast.toast( `Access to ${rs.label} given to ${this.pnE164}`, {
                  title: this.$t('User Right Mangagement'),            
                  solid: true,
                  variant: 'success',
                  duration: 5000
                });
            } else if(!acc.value && rs.authorizedUserOIDList.find(u => u === this.oid) != null){                
              let answ = await SPService.removeUserFromRoomset(API_URL.url, this.$store.state.user.currentUser.jwt, this.oid, rs);
              if(answ.status ===  200) {          
                this.$store.dispatch('fleet/updateRoomsetAuthorizedList', answ.data);
                console.log("updating roomsets")
              }
              this.$bvToast.toast( `Access to ${rs.label} removed to ${this.pnE164}`, {
                title: this.$t('User Right Mangagement'),            
                solid: true,
                variant: 'success',
                duration: 5000
              });
            }
        }
        else {
          console.log('rs not found ')
          console.log(acc);
          console.log(this.$store.state.fleet.roomsetList);
        }
      }              
      
      // close editor
      this.$store.commit("user/SHOW_NOTIFICATIONEDITOR", null);
      } catch(err) {        
        this.$bvToast.toast( `${err.message} creating ${this.pnE164}`, {
                  title: this.$t('Phone Number Management'),            
                  solid: true,
                  variant: 'warning',
                  duration: 5000
                });
        
      }

      this.$store.dispatch('fleet/loadUsers');
      this.requesting = false;

    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },    
    updateRoomAccesses(defIsFalse) {      
      
      if(!this.accesses) return [];
      this.roomAccesses = [];

      for(const rs of this.$store.state.fleet.roomsetList) {
          let acc = this.accesses.find(p => p.oid === rs.oid);
          if(acc && acc.value)
          for(const r of rs.roomList) 
          {            
              this.roomAccesses.push({ label: rs.label+'/' + r.label, oid: r.oid, value: defIsFalse ? false : true });
          }            
      }
      return this.roomAccesses;
    }
    
  },
  mounted() {
    this.requesting = false
    this.$store.commit("user/SHOW_NOTIFICATIONEDITOR", null);
  },
  name: "UserEditionModal"
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" scopped></style>

<style lang="scss" >
.PNValid {
  border-color:  greenyellow;
}

.PNInvalid {
  border-color: red;
}



</style>
